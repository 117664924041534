import { Device, DeviceCommand } from '../../models/Device';

import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';

import styled from 'styled-components';

import { LoadingButton } from '@mui/lab';
import { CircularProgress, IconButton, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DeviceCommandRow, SectionHeaderText } from '../../components/Common';
import { useRecentDeviceCommands } from '../../hooks/useGetDeviceCommands';
import { useSendDeviceCommandMutation } from '../../hooks/useSendDeviceCommand';

interface DeviceCommandsTabProps {
  device: Device;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const NewCommandSection = styled.div`
  margin-bottom: 30px;
`;

const SendCommandBox = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

interface DeviceCommandsListProps {
  device: Device;
  onCommandClick?: (command: DeviceCommand) => void;
}

export const DeviceCommandsList = ({ device, onCommandClick }: DeviceCommandsListProps) => {
  const { data, isLoading, error } = useRecentDeviceCommands({ imei: device.imei });

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (!data || !data.commands || data.commands.length === 0) {
    return (
      <div>No commands.</div>
    );
  }

  const commands = data.commands;

  return (
    <div>
      {commands.map((command) => (
        <DeviceCommandRow key={command.id} command={command} onClick={() => onCommandClick && onCommandClick(command)} />
      ))}
    </div>
  );
};

export const DeviceCommandsTab: React.FC<DeviceCommandsTabProps> = ({ device }) => {

  const [command, setCommand] = useState<string>('');
  const [sendMode, setSendMode] = useState<string>('');
  const textFieldInputRef = useRef<HTMLInputElement>(null); 
  
  const sendDeviceCommandMutation = useSendDeviceCommandMutation();

  useEffect(() => {
    textFieldInputRef.current?.focus();
  }, []);

  const handleSubmit = useCallback(() => {
    sendDeviceCommandMutation.mutate({
      imei: device.imei,
      command,
      medium: sendMode,
    }, {
      onSuccess: () => {
        setCommand('');
        setTimeout(() => textFieldInputRef.current?.focus(), 100);
      }
    })
  }, [sendDeviceCommandMutation, device.imei, command, sendMode]);

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);

  const [supportsSMSCommands, supportsDataCommands] = useMemo(() => {
    return [
      device.features.includes('sms_commands_feature'),
      device.features.includes('data_commands_feature'),
    ];
  }, [device.features]);

  // Set the preselected send mode based on the device's capabilities
  useEffect(() => {
    if (!supportsSMSCommands && !supportsDataCommands) {
      setSendMode('');
    } else if (supportsDataCommands) {
      setSendMode('data');
    } else {
      setSendMode('sms');
    }
  }, [supportsSMSCommands, supportsDataCommands]);

  const onSendModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newSendMode: string,
  ) => {
    setSendMode(newSendMode);
  };

  return (
    <Container>
      <NewCommandSection>
        <SectionHeaderText>New Command</SectionHeaderText>

        <SendCommandBox>
          <TextField
          hiddenLabel
            id="newCommand"
            variant="outlined"
            disabled={sendDeviceCommandMutation.isLoading || (!supportsSMSCommands && !supportsDataCommands)}
            value={command}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCommand(e.target.value)}
            onKeyDown={handleKeyPress}
            inputRef={textFieldInputRef}
            fullWidth
            InputProps={{
              endAdornment: command && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear text"
                    onClick={() => setCommand('')}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}/>

          <LoadingButton
            loading={sendDeviceCommandMutation.isLoading}
            disabled={!command || (!supportsSMSCommands && !supportsDataCommands)}
            loadingPosition="start"
            startIcon={<SendIcon />}
            variant="contained"
            onClick={handleSubmit}>
              Send
          </LoadingButton>

        </SendCommandBox>

        <ToggleButtonGroup
          color="primary"
          value={sendMode}
          exclusive
          onChange={onSendModeChange}
          aria-label="Send Mode"
          style={{ maxHeight: '24px', marginTop: '12px' }}
          disabled={sendDeviceCommandMutation.isLoading}
        >
          <ToggleButton value="sms" disabled={!supportsSMSCommands}>SMS</ToggleButton>
          <ToggleButton value="data" disabled={!supportsDataCommands}>Data</ToggleButton>
        </ToggleButtonGroup>
      </NewCommandSection>

      <div>
        <SectionHeaderText>Recent Commands</SectionHeaderText>
        <DeviceCommandsList device={device} onCommandClick={(command) => setCommand(command.command)}/>
      </div>
    </Container>
  );
};


/**
 * Direction (incoming, outgoing)
 * Command (text)
 * Status (new, issued, received, failed)
 * Medium (data, sms)
 * Time
 */


